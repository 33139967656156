import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonLink } from './links/button-link';
import { mq } from './_shared/media';
import { StyledH2BarBottom, StyledH2 } from './_shared/styled-headings';
import { StyledImageContainer } from './_shared/styled-image-container';
import { flexCenter } from './_shared/styled-mixins';
import { StyledSection } from './_shared/styled-section';
import { StyledTextSection } from './_shared/styled-text-section';

const StyledPostsContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;

  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledPostContainer = styled.article`
  border: 1px solid var(--body-color);
  border-radius: var(--radius);
  background-color: var(--bg-content-color);
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);

  & .gatsby-image-wrapper {
    max-height: 300px;
  }
`;
const StyledDurationOverlay = styled.div`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 3rem;
  width: 3rem;
  color: var(--title-color);
  background: var(--bg-code);
  align-items: center;
  border-radius: var(--radius);
  font-weight: 500;
  line-height: 1rem;
`;
const StyledTitleLink = styled(Link)`
  text-decoration: none;

  &:hover h2 {
    color: var(--primary-color);
  }
`;
const StyledBlogLinkContainer = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 2.5rem;
`;
const StyledPostText = styled(StyledTextSection)`
  > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  margin-bottom: 0.5rem;
`;

const FormationGrid = ({ formations }) => {
  const data = formations.map((formation) => {
    const { title, techs, description, duration } = formation;
    const coverImage = formation.coverImage
      ? formation.coverImage.fluid
      : null;

    const link = formation.promoLink;

    return (
      <StyledPostContainer key={title}>
        <StyledDurationOverlay>
          <span>{duration}</span>
        </StyledDurationOverlay>
        <StyledImageContainer>{coverImage && <Img fluid={coverImage} />}</StyledImageContainer>
        <StyledH2BarBottom>{title}</StyledH2BarBottom>
        <StyledPostText>
          <p>{description.description}</p>
        </StyledPostText>
        <ButtonLink label="Apprendre Maintenant" link={link} />
      </StyledPostContainer>
    );
  });

  return (
    <StyledSection>
      <StyledPostsContainer>{data}</StyledPostsContainer>
    </StyledSection>
  );
};

FormationGrid.propTypes = {
  formations: PropTypes.array.isRequired,
};

export default FormationGrid;
