import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import FormationGrid from '../components/formation-grid';
import SEO from '../components/seo';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledFullHeightSection } from '../components/_shared/styled-section';

const Formations = ({ data }) => {
  const nodes = data.allContentfulFormation.nodes;

  return (
    <Layout menuLinks={blogMenuLinks}>
      <SEO title="Formations" />
      <StyledFullHeightSection>
        <FormationGrid formations={nodes} />
      </StyledFullHeightSection>
    </Layout>
  );
};

export default Formations;

export const query = graphql`
  query {
    allContentfulFormation(sort: {order: DESC, fields: date}) {
      nodes {
        id
        title
        duration
        techs
        slug
        date
        promoLink
        description {
          description
        }
        coverImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
        }
      }
    }
  }
`;

